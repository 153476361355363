import {isMobile} from 'react-device-detect';

import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE, NAV_TYPE_TOP, DIR_LTR} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const APP_NAME = 'Kehila';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const ASSET_BASE_URL = env.ASSET_ENDPOINT_URL
export const PUBLIC_BASE_URL = env.PUBLIC_ENDPOINT_URL
export const WEB_BASE_URL = env.WEB_URL
export const CAMPAGNE_BASE_URL = env.CAMPAGNE_URL
export const ENV = env.ENV
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const EDITOR_PREFIX_PATH = '/editor';
export const REDIRECT_URL_KEY = 'redirect'

export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`
export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
    navCollapsed: localStorage.getItem("navCollapse") == "true" ? true : false,
    sideNavTheme: localStorage.getItem("navStyle") ? localStorage.getItem("navStyle") : SIDE_NAV_LIGHT,
    locale: 'fr',
    navType: localStorage.getItem("navType") ? localStorage.getItem("navType") : NAV_TYPE_SIDE,
    topNavColor: localStorage.getItem("topNavColor") ? localStorage.getItem("topNavColor") : '#F4F6F6',
    headerNavColor: localStorage.getItem("headerNavColor") ? localStorage.getItem("headerNavColor") : '',
    mobileNav: false,
    currentTheme: localStorage.getItem("theme") ? localStorage.getItem("theme") : 'light',
    direction: DIR_LTR,
    blankLayout: false
};
