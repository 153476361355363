import React from 'react'
import {AUTH_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'actu.list',
        path: `${APP_PREFIX_PATH}/actu/list`,
        component: React.lazy(() => import('views/app-views/actualite/mobile')),
    },
    {
        key: 'contact.list',
        path: `${APP_PREFIX_PATH}/contact/list`,
        component: React.lazy(() => import('views/app-views/contact/list')),
    },
    {
        key: 'agenda',
        path: `${APP_PREFIX_PATH}/agenda`,
        component: React.lazy(() => import('views/app-views/agenda/list')),
    },
    {
        key: 'paiement-attente',
        path: `${APP_PREFIX_PATH}/paiement/attente`,
        component: React.lazy(() => import('views/app-views/paiements/list/mobileAttente')),
    },
]
