import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {signOutSuccess} from 'store/slices/authSlice';
import store from '../store';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import {notification} from 'antd';

const unauthorizedCode = [401, 403]

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})


// API Request interceptor
service.interceptors.request.use(config => {
    const token = localStorage.getItem(AUTH_TOKEN) || null;

    if (token) {
        config.headers["authorization"] = token
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Erreur'
    })
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
    return response.data
}, (error) => {

    let notificationParam = {
        message: ''
    }

    // Remove token and redirect
    if (unauthorizedCode.includes(error.response.status)) {
        notificationParam.message = "Échec de l'authentification"
        notificationParam.description = 'Veuillez vous reconnecter'
        localStorage.removeItem(AUTH_TOKEN)

        store.dispatch(signOutSuccess())
    }

    if (error.response.status === 404) {
        notificationParam.message = 'Operation serveur introuvable'
    }

    if (error.response.status === 500 || error.response.status === 400) {
        notificationParam.message = 'Erreur interne du serveur'
    }

    if (error.response.status === 508) {
        notificationParam.message = 'Gateway Timeout'
    }

    notification.error(notificationParam)

    return Promise.reject(error);
});

export default service
