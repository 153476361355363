import React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux';
import {
    AUTH_PREFIX_PATH,
    UNAUTHENTICATED_ENTRY,
    REDIRECT_URL_KEY
} from 'configs/AppConfig'
import PermissionAlert from "../components/permissions/PermissionAlert";
import * as RoutesConfig from 'configs/RoutesConfig';

const ProtectedRoute = () => {

    const {token, access} = useSelector(state => state.auth)
    const location = useLocation()

    if (!token) {
        return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
                         replace/>;
    }

    const route = RoutesConfig.protectedRoutes.find(i => i.path === location.pathname)

    if (!route || access.type === "ADMIN" || access.permissions.includes("all") || !Array.isArray(route.permission) || route.permission.length === 0) {
        return <Outlet/>
    }

    if (route.admin && access.type != "ADMIN") {
        return <PermissionAlert/>
    }

    return (
        <PermissionAlert permissions={route.permission}>
            <Outlet/>
        </PermissionAlert>
    )
}

export default ProtectedRoute
