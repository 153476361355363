import currencies from "currencies.json"

var configs = []
var access = {}

var language="fr"
var devise="eur"
var country="FR"

export const setLanguage = (l) => {
    console.log("SET LANGUAGE",l)
    language = l
}
export const getLanguage = () => {
    return language
}

export const setDevise = (d) => {
    console.log("SET DEVISE",d)
    devise = d
}
export const getDevise = () => {
    return devise
}
export const getDeviseSymbol = () => {
    return currencies.currencies.find(i=>i.code===devise.toUpperCase())?.symbol
}

export const currencyFormat = (amount) => {
    return new Intl.NumberFormat(language, {
        style: 'currency',
        currency: devise.toUpperCase()
    }).format(amount)
}

export const setCountry = (c) => {
    console.log("SET COUNTRY",c)
    country = c
}
export const getCountry = () => {
    return country
}

export const setAccess = (a) => {
    //console.log("SET ACCESS",a)
    access = {...a}
}

export const setConfigs = (c) => {
    //console.log("SET CONFIGS",c)
    configs = [...c]
}

export const configIsExist = (func, type = "CONFIG") => {
    return configs.filter(i => i.type == type).some((i) => func(i))
}

export const getConfig = (key, type = "CONFIG") => {
    //console.log("GET CONFIG",key)
    for (var c of configs) {
        if (c.key == key && c.type == type) {
            return c
        }
    }

    return null
}

export const getConfigs = (key, type = "CONFIG") => {
    const data = []
    for (var c of configs) {
        if (c.key === key && c.type === type) {
            data.push(c)
        }
    }

    return data
}

export const getForfaits = () => {
    //console.log("GET CONFIG",key)
    var data = []
    for (var c of configs) {
        if (c.key == "Forfait" && c.type == "AUTH" && !data.includes(c.value)) {
            data.push(c.value)
        }
    }

    return data
}

export const checkForfait = (accepts = []) => {

    var forfaits = getForfaits()

    if (forfaits.includes("FULL")) {
        if (!accepts.includes("CAMPAGNE") && !accepts.includes("LEGACY_SERVICE")) {
            return true
        }
    }

    for (var a of accepts) {
        if (configIsExist((i) => i.key == "Forfait" && accepts.includes(i.value), "AUTH")) {
            return true
        }
    }
    return false
}


export const checkPermissions = (permissions = []) => {

    if (!access) {
        return false
    }

    if (access.type == "ADMIN" || access.permissions.includes("all")) {
        return true
    }

    for (var p of permissions) {
        if (access.permissions.includes(p)) {
            return true
        }

        if (p.includes(".")) {
            const root = p.split(".")[0]
            if (access.permissions.includes(root)) {
                return true
            }
        }
    }

    return false
}
