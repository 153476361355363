import React from 'react';
import {Routes as RouterRoutes, Route, Navigate} from 'react-router-dom';
import {AUTHENTICATED_ENTRY} from 'configs/AppConfig';
import * as RoutesConfig from 'configs/RoutesConfig';
import * as RoutesMobileConfig from 'configs/RoutesMobileConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import {isMobile} from 'react-device-detect';
import {getUrlFromkey} from "../configs/NavigationConfig";
import {useSelector} from "react-redux";

var publicRoutes = RoutesConfig.publicRoutes
var protectedRoutes = RoutesConfig.protectedRoutes

const Routes = () => {

    const {access} = useSelector(state => state.auth)

    let defaultUrl = AUTHENTICATED_ENTRY
    if (access?.defaultUrlKey) {
        const defaultAccessUrl = getUrlFromkey(access.defaultUrlKey)
        if (defaultUrl) {
            defaultUrl = defaultAccessUrl
        }
    }

    return (
        <RouterRoutes>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Navigate replace to={defaultUrl}/>}/>
                {protectedRoutes.map((route, index) => {
                    return (
                        <Route
                            key={route.key + index}
                            path={route.path}
                            element={
                                <AppRoute
                                    routeKey={route.key}
                                    component={route.component}
                                    {...route.meta}
                                />
                            }
                        />
                    )
                })}
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Route>
            <Route path="/" element={<PublicRoute/>}>
                {publicRoutes.map(route => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AppRoute
                                    routeKey={route.key}
                                    component={route.component}
                                    {...route.meta}
                                />
                            }
                        />
                    )
                })}
            </Route>
        </RouterRoutes>
    )
}

export default Routes
