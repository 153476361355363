import React, {lazy, Suspense, memo, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import {env} from "../configs/EnvironmentConfig";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {

    const token = useSelector(state => state.auth.token);
    const blankLayout = useSelector(state => state.theme.blankLayout);


    useEffect(() => {
        if (env.ENV !== "PROD") {
            document.title = "Kehila - Admin (Dev)"
        }
    }, [])

    const Layout = !token ? AuthLayout : AppLayout

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Layout>
                <Views/>
            </Layout>
        </Suspense>
    )
}

export default memo(Layouts)
