import React, {Fragment} from 'react'
//import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import {ClearCacheProvider, useClearCacheCtx} from 'react-clear-cache';
import moment from "moment";
import "moment/locale/fr"

const container = document.getElementById('root');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript

moment.locale("fr")

const Updater = () => {
    const {isLatestVersion, emptyCacheStorage} = useClearCacheCtx();
    if (!isLatestVersion) {
        emptyCacheStorage().then(() => console.log("NEED REFRESH FOR NEW VERSION"))
    }
    return null;
};

ReactDOM.render(
    <ClearCacheProvider duration={5000}>
        <Updater/>
        <App/>
    </ClearCacheProvider>
    , container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
