import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import AuthService from 'services/AuthService';

import {setAccess, setConfigs, setCountry, setDevise, setLanguage} from "../../components/permissions/functions";

export const initialState = {
    org: null,
    configs: [],
    access: null,
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/login', async (data, {rejectWithValue}) => {
    const {email, password} = data
    try {
        const response = await AuthService.login({email, password})
        const token = response.token;
        const org = response.org
        const access = response.access
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem("ORG", JSON.stringify(org));

        return {token, org, access};
    } catch (err) {
        return rejectWithValue(err.response?.message || 'Error')
    }
})

export const signUp = createAsyncThunk('auth/register', async (data, {rejectWithValue}) => {
    const {email, password} = data
    try {
        const response = await AuthService.register({email, password})
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signOut = createAsyncThunk('auth/logout', async () => {
    const response = await AuthService.logout()
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem("ORG");
    return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateOrg: (state, action) => {
            var configs = [...action.payload?.configs || []]
            setConfigs(configs)
            setLanguage(action.payload?.language || "fr")
            setDevise(action.payload?.devise || "eur")
            setCountry(action.payload?.country || "FR")
            state.org = action.payload
            state.configs = configs
        },
        updateAccess: (state, action) => {
            setAccess(action.payload)
            state.access = action.payload
        },
        authenticated: (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = action.payload.token
            state.org = action.payload.org

            setAccess(action.payload.access)
            state.access = action.payload.access

            var configs = [...action.payload.org?.configs || []]
            setConfigs(configs)
            state.configs = configs
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state) => {
            state.loading = false
            state.token = null
            state.org = null

            setAccess(null)
            state.access = null

            setConfigs([])
            state.configs = []
            state.redirect = '/'
        },
        showLoading: (state) => {
            state.loading = true
        },
        signInSuccess: (state, action) => {
            state.loading = false
            state.token = action.payload.token
            state.org = action.payload.org
            state.access = action.payload.access
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload.token
                state.org = action.payload.org
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload.token
                state.org = action.payload.org
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
    },
})

export const {
    updateOrg,
    updateAccess,
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess
} = authSlice.actions

export default authSlice.reducer
